<template>
  <ion-page>
    <ion-content>
      <ion-card>
        <ion-card-header>
          <ion-card-title>Descargar Gafete</ion-card-title>
        </ion-card-header>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import { TokenUtils } from '@/utils/TokenUtils';
import { IonPage, IonCardHeader, IonCardTitle } from '@ionic/vue';
//import FamilyService from "../services/FamilyService";
import { saveAs } from 'file-saver';
//import { Downloader } from '@ionic-native/downloader/ngx';
//import {NotificationVisibility} from "@ionic-native/downloader";

// eslint-disable-next-line @typescript-eslint/no-var-requires
//let {DownloadRequest} = require("@ionic-native/downloader");

export default {
  name: "qr",
  components: {
    IonPage, IonCardHeader, IonCardTitle
  },
  data: () => ({
    token: null,
    tokenDecode: null,
    familyId: null,
    badges: null,
  }),
  methods: {

  },
  async mounted() {
    console.log('route', this.$route);
    this.token = this.$route.query.token;
    console.log('token', this.token);
    TokenUtils.setToken(this.token);
    this.tokenDecode = TokenUtils.getJwtData();
    console.log('tokenDecode', this.tokenDecode);
    this.familyId = this.tokenDecode.sub;

    //this.badges = await FamilyService.getFamilyBadges(this.familyId);
    //console.log('badges', this.badges);

    /*let request = null
    DownloadRequest = {
      uri: `http://acs.connectit.company/media/documents/family_badges_${this.familyId}.pdf`,
      title: 'MyDownload',
      description: '',
      mimeType: '',
      visibleInDownloadsUi: true,
      notificationVisibility: NotificationVisibility.VisibleNotifyCompleted,
      destinationInExternalFilesDir: {
        dirType: 'Downloads',
        subPath: 'MyFile.apk'
      }
    };

    request = DownloadRequest;

    Downloader.download(request)
        .then((location = "") => console.log('File downloaded at:'+location))
        .catch((error = "") => console.error(error));*/

    const executeSaveAs = (content) => {
      const blob = new Blob([content], {'type': "application/octet-stream"});
      saveAs(blob, "downloaded_zip.zip"); // This is from https://github.com/eligrey/FileSaver.js
    };

    return this.http.get(`http://acs.connectit.company/media/documents/family_badges_${this.familyId}.pdf`, {responseType: 'arraybuffer'}).pipe(executeSaveAs);
  },
}
</script>

<style scoped>

</style>
